span {
  display: block;
}

input:invalid[focused="true"] ~ span {
  display: block;
}

input:invalid[focused="true"] {
}
