.scrollTop {
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(196, 190, 182, 0.6);
  border-radius: 50px;
  cursor: pointer;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 600px) {
  .scrollTop {
    display: flex;
  }
}

.scrollTop .icon {
  height: 32px;
  width: 32px;
  opacity: 0.6;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
